<template>
	<div class="myOrgInfo">
		<div class="orgName">所属机构：{{user.organ_name}}</div>
		<div class="orgName">所属部门：{{user.organ_branch_name}}</div>
		<div class="orgName" style="width: 100%;" v-if="!studyShow && user.is_respon == 1">
			组内成员：
			<temp-data v-if="showTempData"></temp-data>
			<div v-else style="margin-left: -30px;">
			<el-table
			    :data="teamOrg.data[page-1]"
			    :header-cell-style="{background:'#F9FAFC',fontWeight:'normal',textAlign:'center',fontSize:'16px',color:'#333'}"
			    :cell-style="{textAlign:'center',fontSize:'14px',color:'#333'}"
			    border
			    style="width: 915px;margin-top:20px;margin-left:20px;">
			    <el-table-column
			    label="排名"
			    prop="ranking"
			    width="180">
			    </el-table-column>
			    <el-table-column
			    prop="name"
			    label="头像"
			    width="180">
			    <template slot-scope="scope">
			        <img :src="scope.row.portrait" style="width:60px;height:60px;border-radius:100px;" alt="">
			    </template>
			    </el-table-column>
			    <el-table-column
			    prop="name"
			    label="姓名">
				<template slot-scope="scope">
				    <span style="font-size:14px;">{{scope.row.real_name ? scope.row.real_name : scope.row.name}}</span>
				</template>
			    </el-table-column>
			    <el-table-column
			    prop="branch_off"
			    label="部门科室">
			    </el-table-column>
			    <el-table-column
			    prop="ctime"
			    label="加入时间">
			    </el-table-column>
			    <el-table-column
			    prop="address"
			    label="积分数量">
			    <template slot-scope="scope">
			        <span style="font-size:14px;color:#47D7E3;">{{scope.row.point}}积分</span>
			    </template>
			    </el-table-column>
			    <el-table-column
			    prop="address"
			    label="学习进度">
			    <template slot-scope="scope">
			        <span style="font-size:14px;color:#47D7E3;cursor: pointer;" @click="readInfo(scope.row)">查看</span>
			    </template>
			    </el-table-column>
			</el-table>
			<el-pagination
			style="margin-top:37px;"
			background
			layout="prev, pager, next"
			:total="teamOrg.count"
			:page-size="10"
			@current-change="currentChange">
			</el-pagination>
			</div>
		</div>
		
		
		<div class="redStudy" v-else v-show="user.is_respon == 1">
		    <div class="flex spacebetween">
				<div class="personInfo flex alignCenter">
					<img :src="catchData.portrait" style="width:46px;height:46px;border-radius: 50px;overflost:hidden;" alt="">
					<div style="height:100%;margin-left:18px;align-items: flex-start;" class="flexColumn flex spacebetween">
						<span class="c33 fontW" style="font-size:15px;">{{catchData.name}}</span>
						<span class="c33" style="font-size:15px;">{{catchData.point}}</span>
					</div>
					<div class="xunz" :style="{background:`url(${catchData.icon})`}">
						{{catchData.medal_name}}
					</div>
				</div>
				<span style="padding-right:30px;color:#47D7E3;" @click="studyShow = false" class="fontW cursorP">返回</span>
		    </div>
		    <table class="" cellspacing="0">
		        <tr style="height:55px;background: #FAFAFA;">
		            <th style="width: 500px;">课程名称</th>
		            <th>学习进度</th>
		        </tr>
		        <tr v-for="(im,ix) in studyData[page-1]" :key="ix">
		            <td>{{im.c_title}}</td>
		            <td>{{im.rate == '0%' ? '未开始' : im.rate}}</td>
		        </tr>
		    </table>
		    <el-pagination
		    style="margin-top:37px;"
		    background
		    layout="prev, pager, next"
		    :total="studyDataCount"
		    :page-size="10"
		    @current-change="currentChangeStudy">
		    </el-pagination>
		</div>
		
		
		
	</div>
</template>

<script>
	import { respon_people, member_look_log } from '@/utils/Api/userList'
	import tempData from '@/components/tempData'
	export default {
		name: 'myOrgInfo',
		components:{tempData},
		computed:{
			user(){
				return this.$store.getters.personal
			}
		},
		data(){
			return{
				page: 1,
				showTempData: false,
				teamOrg:{
					count:0,
					data:[]
				},
				page1: 1,
				catchData:{},
				studyData:[],
				studyDataCount:0,           // 查看学习记录分页
				studyShow: false
			}
		},
		created() {
			console.log(this.user)
			this.initialBrfore()
		},
		methods:{
			readInfo(val){
			    console.log(val)
			    this.page1 = 1
			    this.catchData = val
			    this.getStudyPress()
			},
			async getStudyPress(val){
			    let params = {
			        page: this.page1,
			        limit: 10,
			        user_id: this.catchData.id
			    }
			    let cbk = await member_look_log(params)
			    console.log(cbk)
				if(!cbk.res){
					this.$message.error('学习进度查询失败：无数据')
					return
				}
			    if(cbk.res.length > 0){
			        this.studyDataCount = cbk.count
			        this.studyData[this.page-1] = cbk.res
			        this.studyShow = true
			        console.log(this.studyData)
			    }
			},
			initialBrfore(){
			    let params = {
			        page: this.page,
			        limit: 10,
			    }
			    this.initial(params)
			},
			async initial(params){
			    let teamOrg = await respon_people(params)
			    console.log(teamOrg)
			    if(teamOrg.count){
			        this.teamOrg.count = teamOrg.count
			    
			        let start = 10 * (this.page - 1)
			        teamOrg.res.forEach((im,ix) => {
			            im.ranking = start+ix + 1
			        })
			    
			        this.teamOrg.data[this.page - 1] = teamOrg.res
			        this.showTempData = false
			        this.$forceUpdate()
			    }else{
			        this.showTempData = true
			        console.log('11111111111111111')
			    }
			    console.log(this.teamOrg)
			},
			currentChange(value){
			    this.page = value
			    this.initialBrfore()
			    this.scrollTop()
			},
			currentChangeStudy(value){
			    this.page1 = value
			    this.getStudyPress()
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.myOrgInfo{
		text-align: left;
		.orgName{
			line-height: 50px;
			width: 600px;
			margin-left: 30px;
			border-bottom: 1px solid #EDEDED;
		}
	}
	.xunz{
	    width: 80px;
	    height: 20px;
	    margin-left: 10px;
	    background-size: cover!important;
	    color: #fff;
	    font-size: 10px;
	    line-height: 27px;
	    text-align: center;
	}
	.personInfo{
	    height: 46px;
	}
	.redStudy{
	    margin-left: 20px;
	    margin-top: 50px;
	    table{
	        width: 850px;
	        margin-top: 40px;
	        tr{
	            height: 82px;
	        }
	        th{
	            font-size: 14px;
	        }
	        td{
	            color: #333;
	            font-size: 15px;
	            border: 1px solid #e6e6e6;
	        }
	    }
	}
	button{
	    font-size: 16px;
	    color: #fff;
	    padding: 11px 10px;
	    background: #47D7E3;
	    margin-left: 9px;
	    cursor: pointer;
	}
	.member{
	    .operList{
	        margin-top: 20px;
	        margin-left: 20px;
	        text-align: left;
	    }
	}
</style>
